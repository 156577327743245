
import { ref, Ref, watch } from "vue";

export default {
    name: "PlayerStats",
    props: {
        baseHp: {
            type: Number,
            default: 20,
        },
        resetTrigger: {
          type: Boolean,
          default: true,
        }
    },
    setup(props: any) {

//const
    const counter: Ref<number> = ref(props.baseHp)

// methods

    const manageLife = (event: string) => {
      if (event === '-') {
        counter.value --
      } else if ( event === '+') {
        counter.value ++
      }
    }

// watch

  watch(() => props.resetTrigger, () => {
      counter.value = props.baseHp
    })
    return { counter, manageLife }
  }
}
