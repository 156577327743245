
import { ref, Ref } from 'vue';
import PlayerStats from './PlayerStats.vue';
import Settings from './Settings.vue';

export default {
    name: 'Game', 
    components: {
      PlayerStats,
      Settings
    },
    setup() {

//const
const resetTriggerBool: Ref<boolean> = ref(true);

// methods
// watch

return { resetTriggerBool }
  }
}
