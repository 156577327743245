import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createI18n } from 'vue-i18n'
import { fr } from "./i18n/fr"

const i18n = createI18n({
    messages: {'fr-fr': fr},
    locale: 'fr-fr',
    fallbackLocale: 'fr-fr'
  // something vue-i18n options here ...
})
createApp(App).use(router).use(i18n).mount("#app");
