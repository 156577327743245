import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "game" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayerStats = _resolveComponent("PlayerStats")!
  const _component_Settings = _resolveComponent("Settings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PlayerStats, {
      id: "player1",
      "reset-trigger": $setup.resetTriggerBool
    }, null, 8, ["reset-trigger"]),
    _createVNode(_component_Settings, {
      onReset: _cache[0] || (_cache[0] = ($event: any) => ($setup.resetTriggerBool = !$setup.resetTriggerBool))
    }),
    _createVNode(_component_PlayerStats, {
      id: "player2",
      "reset-trigger": $setup.resetTriggerBool
    }, null, 8, ["reset-trigger"])
  ]))
}