

export default {
  name: "Settings",
    props: {
    },
    emits: ['reset'],
    setup(props: any, emits: any) {

//const

// methods
    const reset = () => {
      emits.emit('reset')
    }

// watch

    return {reset}
  }
}
